/** @jsx jsx */
// @ts-ignore  (Flex import is fine)
import { jsx, Themed, Flex } from "theme-ui"
import CallOutForm from "./callout-box"
import React from "react"
//@ts-ignore
import { Share, Follow } from "react-twitter-widgets"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

type TwitterShareCardProps = {
  title: string
  slug: string
  description?: string
}
const TwitterShareCard = ({
  title,
  slug,
  description,
}: TwitterShareCardProps) => {
  const { site, avatar } = useStaticQuery(
    graphql`
      {
        avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
          childImageSharp {
            gatsbyImageData(width: 100, layout: CONSTRAINED)
          }
        }
        site {
          siteMetadata {
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )
  const { siteUrl, social } = site.siteMetadata
  return (
    <CallOutForm
      borderLeftColor={`twitterLeftBar`}
      backgroundColor={`twitterBackground`}
    >
      <Flex sx={{ flexWrap: ["wrap-reverse", "nowrap"] }}>
        <Themed.div sx={{ flexGrow: 1 }}>
          {" "}
          <Themed.h3
            sx={{ color: `text`, marginTop: "1em", marginBottom: "0.5em" }}
          >
            Share This On Twitter
          </Themed.h3>
          {description ? (
            <Themed.p sx={{ marginTop: 0 }}>{description}</Themed.p>
          ) : (
            <React.Fragment>
              <Themed.p sx={{ marginTop: 0 }}>
                {
                  "If you liked this post, please consider sharing it with your network. If you have any questions, tweet away and I’ll answer :)\n I also tweet when new posts drop!"
                }
              </Themed.p>
              <Themed.p>
                <Themed.strong>PS: </Themed.strong> I also share helpful tips
                and links as I'm learning - so you get them{" "}
                <Themed.strong>well before</Themed.strong> they make their way
                into a post!{" "}
              </Themed.p>
            </React.Fragment>
          )}
          <Flex>
            <Share
              url={siteUrl + slug}
              options={{
                size: "large",
                text: title,
                via: social.twitter,
              }}
            />
            <Themed.div sx={{ marginX: "1em" }}>
              <Follow
                username="mukulrathi_"
                options={{ size: "large", showCount: "false" }}
              />
            </Themed.div>
          </Flex>
        </Themed.div>

        <Themed.div sx={{ flexGrow: 1, marginLeft: "1em" }}>
          <Themed.div
            sx={{
              marginTop: "1.5em",
              marginX: "auto",
              maxWidth: ["120px", "100%"],
              minWidth: ["50px", "120px"],
              borderRadius: `100%`,
            }}
          >
            <GatsbyImage
              image={avatar.childImageSharp.gatsbyImageData}
              alt={"Mukul Rathi"}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
          </Themed.div>
        </Themed.div>
      </Flex>
    </CallOutForm>
  )
}

export default TwitterShareCard
