/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { Link } from "gatsby"
import { BlogPostTemplateNode } from "../templates/blog-post"

type PostSeriesProps = {
  posts: { node: BlogPostTemplateNode }[]
  currentPost: BlogPostTemplateNode
}

function createPostItem(
  post: BlogPostTemplateNode,
  currentPost: BlogPostTemplateNode
) {
  const postItemPart = "Part " + post.frontmatter.part + ": "
  const postItemContent = postItemPart + post.frontmatter.title
  if (post.frontmatter.audience == "me") {
    return <em>{postItemContent + "*"}</em>
  } else if (post.frontmatter.audience == "email") {
    return <em>{postItemContent + "**"}</em>
  }
  if (post.frontmatter.title == currentPost.frontmatter.title) {
    return (
      <Themed.strong sx={{ color: `secondary` }}>
        {postItemContent}
      </Themed.strong>
    )
  }
  return (
    <Themed.div
      sx={{
        marginBottom: "0em",
        color: `text`,
      }}
    >
      <span> {postItemPart} </span>
      <Link
        style={{
          boxShadow: `none`,
          color: `inherit`,
        }}
        to={post.fields.slug}
      >
        {post.frontmatter.title}
      </Link>
    </Themed.div>
  )
}

const PostSeries = ({ posts, currentPost }: PostSeriesProps) => {
  if (posts.length <= 1) {
    return null
  }
  const sortedPosts = posts.sort((post1, post2) => {
    let post1Part = post1.node.frontmatter.part
    let post2Part = post2.node.frontmatter.part
    if (post1Part != null && post2Part != null) {
      return post1Part - post2Part
    } else return 0
  })
  const hasDrafts =
    posts.filter(post => post.node.frontmatter.audience === "me").length >= 1
  const hasEmailOnly =
    posts.filter(post => post.node.frontmatter.audience === "email").length >= 1
  return (
    <nav>
      <Themed.h2
        sx={{
          textTransform: "uppercase",
          fontSize: 2,
          fontWeight: `bold`,
          color: `secondary`,
        }}
      >
        {"Series: " + currentPost.frontmatter.series}
      </Themed.h2>
      <ul>
        {sortedPosts.map((post, index) => (
          <Themed.li key={index} sx={{ listStyleType: `none` }}>
            {createPostItem(post.node, currentPost)}
          </Themed.li>
        ))}
        {hasDrafts && (
          <Themed.div sx={{ color: `secondary` }}>*coming soon!</Themed.div>
        )}
        {hasEmailOnly && (
          <Themed.div sx={{ color: `secondary` }}>
            **available only to email subscribers!{" "}
            <a href="https://buttondown.email/mukulrathi">Sign up here</a>!
          </Themed.div>
        )}
      </ul>
    </nav>
  )
}

export default PostSeries
