/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

type CallOutBoxProps = {
  borderLeftColor: string
  backgroundColor: string
  children: React.ReactNode
}

const CallOutBox = ({
  borderLeftColor,
  backgroundColor,
  children,
}: CallOutBoxProps) => (
  <Themed.div
    sx={{
      color: `text`,
      borderLeft: `10px solid`,
      backgroundColor: backgroundColor,
      borderColor: borderLeftColor,
      paddingX: "1.5em",
      paddingY: "0.5em",
      borderRadius: "10px",
      marginX: "auto",
      marginY: "1em",
      h3: {
        marginTop: "1em",
      },
    }}
  >
    {children}
  </Themed.div>
)

export default CallOutBox
