/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { AnchorLink } from "gatsby-plugin-anchor-links"

type Post = {
  body: any
  tableOfContents: any
  frontmatter: {
    title: string
    datePublished: string
    description: string
    include_KaTeX: boolean
  }
  fields: {
    slug: string
  }
}

type Heading = {
  items: Heading[]
  url: string
  title: string
}

type TableOfContentsProps = { post: Post }

function createHeadings(headings: Heading[], slug: string, depth: number) {
  if (!headings || depth >= 3) {
    return null
  }
  return headings.map((heading, index: number) => (
    <Themed.li
      key={slug + (heading.url || depth + `-` + index)}
      sx={{ listStyleType: `none` }}
    >
      {heading.url && (
        <AnchorLink
          to={slug + heading.url}
          css={{
            textDecoration: `none`,
          }}
        >
          <Themed.h3
            sx={{
              fontSize: 1,
              color: `text`,
              marginY: "0.25em",
              textDecoration: `none`,
              fontWeight: depth <= 1 ? `bold` : `normal`,
              ":hover": {
                color: `secondary`,
              },
            }}
          >
            {heading.title}{" "}
          </Themed.h3>
        </AnchorLink>
      )}
      <ul>{createHeadings(heading.items, slug, depth + 1)}</ul>
    </Themed.li>
  ))
}

const Headings = ({
  headings,
  slug,
}: {
  headings: Heading[]
  slug: string
}) => {
  if (headings == undefined) {
    return null
  }
  return <ul>{createHeadings(headings, slug, 1)}</ul>
}

const TableOfContents = ({ post }: TableOfContentsProps) => (
  <Themed.div
    sx={{
      position: "sticky",
      height: "100vh",
      top: 0,
      right: 0,
      width: "300px",
      alignSelf: "start",
      paddingLeft: "10px",
      paddingRight: "20px",
      paddingY: "1em",
      backgroundColor: `secondaryBackground`,
    }}
  >
    <nav>
      <Themed.h2
        sx={{
          textTransform: "uppercase",
          fontSize: 2,
          fontWeight: `bold`,
          color: `secondary`,
          marginTop: "1em",
        }}
      >
        Table of Contents
      </Themed.h2>
      <AnchorLink
        to={post.fields.slug + "#top-of-page"}
        css={{
          textDecoration: `none`,
        }}
      >
        <Themed.h3
          sx={{
            fontSize: 2,
            color: `text`,
            marginTop: "1em",
            fontWeight: `light`,
            marginBottom: 0,
            marginLeft: "1em",
            textDecoration: `none`,
            ":hover": {
              color: `secondary`,
            },
          }}
        >
          {post.frontmatter.title}
        </Themed.h3>
      </AnchorLink>

      <Headings headings={post.tableOfContents.items} slug={post.fields.slug} />
    </nav>
  </Themed.div>
)

export default TableOfContents
