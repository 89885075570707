import React from "react"
import Helmet from "react-helmet"

const Hypothesis = () => {
  const config = {
    openSidebar: false,
    showHighlights: "always",
    theme: "classic",
  }

  return (
    <Helmet>
      <script type="application/json" className="js-hypothesis-config">
        {JSON.stringify(config)}
      </script>
      <script async src="https://hypothes.is/embed.js"></script>
    </Helmet>
  )
}

export default Hypothesis
