import React from "react"
import { MDXProvider } from "@mdx-js/react"
import CallOutBox from "./callout-box"
import EmailSubscriptionForm from "./email-subscription-form"
import TwitterShareCard from "./twitter-share-card"
import ThemedTweet from "./themed-tweet"

export const shortCodes = {
  Tweet: ThemedTweet,
  TwitterShareCard,
  EmailSubscriptionForm,
  blockquote: (props: { children: React.ReactNode }) => (
    <CallOutBox
      borderLeftColor={`blockQuoteLeftBar`}
      backgroundColor={`blockQuoteBackground`}
    >
      {props.children}
    </CallOutBox>
  ),
}

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <MDXProvider components={shortCodes}>{element}</MDXProvider>
)
