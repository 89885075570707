import { Tweet } from "react-twitter-widgets"
import { useColorMode } from "theme-ui"
import React from "react"

const ThemedTweet = (props: { tweetId: string }) => {
  const [colorMode, _] = useColorMode()
  const theme = colorMode === "dark" ? "dark" : "light"
  return <Tweet tweetId={props.tweetId} options={{ theme }} />
}

export default ThemedTweet
