/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

type YouTubeEmbedProps = {
  youTubeVideoId: string
}

type SiteQueryType = {
  site: {
    siteMetadata: {
      social: {
        youtube: string
      }
    }
  }
}

const YouTubeEmbed = ({ youTubeVideoId }: YouTubeEmbedProps) => {
  const {
    site: {
      siteMetadata: {
        social: { youtube },
      },
    },
  }: SiteQueryType = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              youtube
            }
          }
        }
      }
    `
  )

  return (
    <Themed.div sx={{ marginBottom: "1em" }}>
      <Themed.h2>Check this post out on YouTube!</Themed.h2>
      <Themed.p>
        <Themed.a href={youtube}>Consider subscribing</Themed.a> while you're
        there?
      </Themed.p>
      <Themed.div
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: "56.25%",
        }}
      >
        <Themed.div
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src={"https://www.youtube.com/embed/" + youTubeVideoId}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </Themed.div>
      </Themed.div>
    </Themed.div>
  )
}
export default YouTubeEmbed
