/** @jsx jsx */
import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { jsx, Themed, Flex } from "theme-ui"
//@ts-ignore
import { useBreakpointIndex } from "@theme-ui/match-media"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TableOfContents from "../components/table-of-contents"
import PostSeries from "../components/post-series"
import TwitterShareCard from "../components/twitter-share-card"
import YouTubeEmbed from "../components/youtube-embed"
import EmailSubscriptionForm from "../components/email-subscription-form"
import { shortCodes } from "../components/mdx-shortcodes"
import Hypothesis from "../components/hypothesis"

export type BlogPostTemplateNode = {
  id: number
  timeToRead: number
  body: any
  tableOfContents: any
  frontmatter: {
    series?: string
    part?: number
    title: string
    datePublished: string
    dateModified?: string
    audience: "me" | "all" | "email"
    description: string
    image: {
      childImageSharp: {
        fluid: any
      }
    }
    isFeatured?: boolean
    tags?: string[]
    twitterDescription?: string
    FAQs?: { question: string; answer: string }[]
    youTubeVideoId?: string
  }
  fields: {
    slug: string
  }
}

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  mdx: BlogPostTemplateNode
}

// @ts-ignore ( PageProps is fine)
const BlogPostTemplate = ({ data, pageContext, location }: PageProps<Data>) => {
  const breakPointIndex = useBreakpointIndex()
  const shouldDisplayToC = breakPointIndex >= 2
  const post = data.mdx
  const {
    title,
    image: imageNode,
    caption,
    include_KaTeX,
    description,
    datePublished,
    dateModified,
    series,
    part,
    FAQs,
    twitterDescription,
    youTubeVideoId,
  } = post.frontmatter
  const { previous, next } = pageContext
  let showPrevious = previous?.frontmatter?.audience === "all"
  let showNext = next?.frontmatter?.audience === "all"

  if (include_KaTeX) {
    require(`katex/dist/katex.min.css`)
  }
  const MainContent = () => (
    <Themed.div
      sx={{
        display: "block",
        marginLeft: ["3em", "auto"],
        marginRight: ["3em", "auto"],

        maxWidth: ["600px", "600px", "700px"],
      }}
    >
      <article>
        {series && (
          <Themed.h3
            sx={{
              marginBottom: "0.5em",
              marginTop: "5em",
              fontSize: 3,
              textTransform: `uppercase`,
            }}
          >
            {series + ": Part " + part}
          </Themed.h3>
        )}
        <Themed.h1
          id="top-of-page"
          sx={{
            marginTop: series ? 0 : "1.5em",
            marginBottom: 0,
            paddingY: "0.25em",
          }}
        >
          {title}
        </Themed.h1>
        <Flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Themed.h3
            sx={{
              marginY: "1.0em",
              color: `text`,
              marginRight: "1em",
              fontSize: 2,
              textTransform: `uppercase`,
            }}
          >
            {datePublished}
          </Themed.h3>
          <Themed.h3
            sx={{
              marginY: "1.0em",
              marginLeft: "1em",
              color: `text`,
              fontSize: 2,
              textTransform: `uppercase`,
            }}
          >
            {post.timeToRead + " min read"}
          </Themed.h3>
        </Flex>
        {dateModified && (
          <Themed.h3
            sx={{
              marginY: "1.5em",
              color: `text`,
              fontSize: 2,
            }}
          >
            {"Last updated: " + dateModified}
          </Themed.h3>
        )}
        {imageNode && <GatsbyImage image={getImage(imageNode)} alt={caption} />}
        <PostSeries posts={pageContext.seriesPosts} currentPost={post} />
        <hr
          style={{
            marginBottom: "3em",
          }}
        />
        {post.frontmatter.audience === "email" && (
          <EmailSubscriptionForm
            heading="Sent here by a friend?"
            description="This post is currently an email exclusive. Sign up to get these posts first -  delivered straight to your inbox!"
          />
        )}
        {youTubeVideoId && <YouTubeEmbed youTubeVideoId={youTubeVideoId} />}
        <MDXProvider components={shortCodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </article>
      <TwitterShareCard
        title={title}
        slug={post.fields.slug}
        description={twitterDescription}
      />
      <PostSeries posts={pageContext.seriesPosts} currentPost={post} />
      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {showPrevious && (
              <Themed.h3
                sx={{
                  marginBottom: "0em",
                  fontSize: 2,
                  color: `secondary`,
                }}
              >
                <Link
                  to={previous.fields.slug}
                  rel="prev"
                  style={{
                    boxShadow: `none`,
                    color: `inherit`,
                  }}
                >
                  ← {previous.frontmatter.title}
                </Link>
              </Themed.h3>
            )}
          </li>
          <li>
            {showNext && (
              <Themed.h3
                sx={{
                  marginBottom: "0em",
                  fontSize: 2,
                  color: `secondary`,
                }}
              >
                <Link
                  to={next.fields.slug}
                  rel="next"
                  style={{
                    boxShadow: `none`,
                    color: `inherit`,
                  }}
                >
                  {next.frontmatter.title} →
                </Link>
              </Themed.h3>
            )}
          </li>
        </ul>
      </nav>
    </Themed.div>
  )
  return (
    <Layout page={location.pathName}>
      <SEO
        pageType="blogPost"
        FAQs={FAQs}
        title={title}
        description={description}
        imageNode={imageNode}
        datePublished={datePublished}
        dateModified={dateModified}
        path={post.fields.slug}
      />
      <Hypothesis />
      {shouldDisplayToC ? (
        <Flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <MainContent />
          <TableOfContents post={post} />
        </Flex>
      ) : (
        <MainContent />
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      timeToRead
      frontmatter {
        series
        part
        title
        datePublished(formatString: "MMMM DD, YYYY")
        dateModified(formatString: "MMMM DD, YYYY")
        audience
        description
        image {
          childImageSharp {
            gatsbyImageData(width: 630, layout: CONSTRAINED)
          }
        }
        include_KaTeX
        isFeatured
        tags
        twitterDescription
        FAQs {
          question
          answer
        }
        youTubeVideoId
      }
      fields {
        slug
      }
    }
  }
`
